import chevronLeft from './chevronLeft.svg';
import cross from './cross.svg';
import culturePulseLogo from './culturePulseLogo.svg';
import culturePsychLogo from './culturePsychLogo.svg';
import tick from './tick.svg';
import dragHandle from './dragHandle.svg';

export default new Map([
    ['chevronLeft', chevronLeft],
    ['cross', cross],
    ['culturePulseLogo', culturePulseLogo],
    ['culturePsychLogo', culturePsychLogo],
    ['tick', tick],
    ['dragHandle', dragHandle],
]);
