export const ROUTES = {
    BIODATA: '/biodata',
    PERSONAL_DESCRIPTORS: '/personal-descriptors',
    STANDARD_DESCRIPTORS: '/standard-descriptors',
    REFINE_DESCRIPTORS: '/refine-descriptors',
    RANK_DESCRIPTORS: '/rank-descriptors',
    DESCRIPTOR_EXAMPLES: '/descriptor-examples',
    DESCRIPTOR_SENTIMENT: '/descriptor-sentiment',
    THANK_YOU: '/thank-you',
    SURVEY_CLOSED: '/survey-closed',
};

export const SURVEY_STEPS = [
    ROUTES.PERSONAL_DESCRIPTORS,
    ROUTES.STANDARD_DESCRIPTORS,
    ROUTES.REFINE_DESCRIPTORS,
    ROUTES.RANK_DESCRIPTORS,
    ROUTES.DESCRIPTOR_EXAMPLES,
    ROUTES.DESCRIPTOR_SENTIMENT,
];

export const URL = {
    SUBMIT_SURVEY: process.env.SURVEY_URL + '/api/surveyresponse',
    VALIDATE_SURVEY: process.env.SURVEY_URL + '/api/surveys',
    SURVEY_WORDS: process.env.SURVEY_WORDS_URL + '/api/insightwords',
    SURVEY_FIELDS: process.env.SURVEY_URL + '/api/surveyfields',
};

export const genders = [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Other', value: 'other' },
];

export const ageGroups = [
    {
        label: '16 - 20',
        value: '16to20',
    },
    {
        label: '21 - 25',
        value: '21to25',
    },
    {
        label: '26 - 30',
        value: '26to30',
    },
    {
        label: '31 - 35',
        value: '31to35',
    },
    {
        label: '36 - 40',
        value: '36to40',
    },
    {
        label: '41 - 45',
        value: '41to45',
    },
    {
        label: '46 - 50',
        value: '46to50',
    },
    {
        label: '51 - 60',
        value: '51to60',
    },
    {
        label: '60+',
        value: '60plus',
    },
];

export const employmentTypes = [
    {
        label: 'Casual',
        value: 'casual',
    },
    {
        label: 'Full-time or part-time',
        value: 'fullTimeOrPartTime',
    },
];

export const shiftsPerWeekOptions = [
    {
        label: '2 or less per week',
        value: '2orLess',
    },
    {
        label: '3 or more per week',
        value: '3orMore',
    },
];

export const tenures = [
    {
        label: 'Less than 6 months',
        value: 'lessThan6months',
    },
    {
        label: '6 months - 1 year',
        value: '6monthsTo1year',
    },
    {
        label: '1 - 2 years',
        value: '1to2years',
    },
    {
        label: '3 - 5 years',
        value: '3to5years',
    },
    {
        label: '6 - 8 years',
        value: '6to8years',
    },
    {
        label: '9+ years',
        value: '9plusYears',
    },
];
