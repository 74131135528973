import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ROUTES, URL } from './constants';
import { Layout, Spinner } from './components';
import { useSurvey } from '~/contexts';

const Biodata = lazy(() => import('./steps/Biodata'));
const PersonalDescriptors = lazy(() => import('./steps/PersonalDescriptors'));
const StandardDescriptors = lazy(() => import('./steps/StandardDescriptors'));
const RefineDescriptors = lazy(() => import('./steps/RefineDescriptors'));
const RankDescriptors = lazy(() => import('./steps/RankDescriptors'));
const DescriptorExamples = lazy(() => import('./steps/DescriptorExamples'));
const DescriptorSentiment = lazy(() => import('./steps/DescriptorSentiment'));
const ThankYou = lazy(() => import('./steps/ThankYou'));
const SurveyClosed = lazy(() => import('./steps/SurveyClosed'));

export const Router = () => {
    const { surveySubmitted } = useSurvey();
    const { surveyId, openForResponses, validating } = useValidateSurvey();

    if (validating) return <Spinner />;

    const currPath = window.location.pathname;

    const invalidCheck =
        (!surveyId || !openForResponses) && currPath !== ROUTES.SURVEY_CLOSED;

    const submittedCheck =
        surveySubmitted &&
        currPath !== ROUTES.THANK_YOU &&
        currPath !== ROUTES.SURVEY_CLOSED;

    return (
        <Layout>
            <Suspense fallback={<Spinner />}>
                <Switch>
                    {(invalidCheck || submittedCheck) && (
                        <Redirect to={ROUTES.SURVEY_CLOSED} />
                    )}
                    <Route exact path={ROUTES.BIODATA} component={Biodata} />
                    <Route
                        exact
                        path={ROUTES.PERSONAL_DESCRIPTORS}
                        component={PersonalDescriptors}
                    />
                    <Route
                        exact
                        path={ROUTES.STANDARD_DESCRIPTORS}
                        component={StandardDescriptors}
                    />
                    <Route
                        exact
                        path={ROUTES.REFINE_DESCRIPTORS}
                        component={RefineDescriptors}
                    />
                    <Route
                        exact
                        path={ROUTES.RANK_DESCRIPTORS}
                        component={RankDescriptors}
                    />
                    <Route
                        exact
                        path={ROUTES.DESCRIPTOR_EXAMPLES}
                        component={DescriptorExamples}
                    />
                    <Route
                        exact
                        path={ROUTES.DESCRIPTOR_SENTIMENT}
                        component={DescriptorSentiment}
                    />
                    <Route exact path={ROUTES.THANK_YOU} component={ThankYou} />
                    <Route
                        exact
                        path={ROUTES.SURVEY_CLOSED}
                        component={SurveyClosed}
                    />
                    <Redirect
                        exact
                        from="/"
                        to={{
                            pathname: ROUTES.BIODATA,
                            search: window.location.search,
                        }}
                    />
                    <Route component={() => <div>Not found</div>} />
                </Switch>
            </Suspense>
        </Layout>
    );
};

const useValidateSurvey = () => {
    const [validationState, setValidationState] = useState({
        validating: true,
        openForResponses: false,
        surveyId: null,
    });

    const surveyId = useSurveyId();

    useEffect(() => {
        (async () => {
            if (surveyId) {
                try {
                    const { openForResponses } = await fetch(
                        URL.VALIDATE_SURVEY,
                        {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ surveyId }),
                        }
                    ).then(res => res.json());

                    setValidationState({
                        validating: false,
                        openForResponses,
                        surveyId,
                    });
                } catch {
                    setValidationState(invalidSurveyIdState);
                }
            } else {
                setValidationState(invalidSurveyIdState);
            }
        })();
    }, []);

    return validationState;
};

const invalidSurveyIdState = {
    validating: false,
    openForResponses: false,
    surveyId: null,
};

export const useSurveyId = () =>
    new URLSearchParams(useLocation().search).get('surveyId');
