import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const StyledLink = styled(
    ({ unstyled, ...props }) => <ReactRouterLink {...props} />,
)`
    text-decoration: none;
    ${p => !p.unstyled && css`
        color: ${p => p.theme.primary};
        &:hover {
            text-decoration: underline;
        }
    `}
`;

export const Link = ({
    children,
    to,
    ...props
}) => (
    <StyledLink
        to={location => ({
            ...location,
            pathname: to,
        })}
        {...props}
    >
        {children}
    </StyledLink>
);
