import React from 'react';

import { Loading } from './Loading';

export const LazyRender = ({
    loading,
    fill,
    children,
    ...props
}) => {
    if (loading) {
        return (
            <Loading
                fill={fill}
                {...props}
            />
        );
    }

    return children;
};
