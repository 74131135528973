import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 8px;
    color: ${p => p.theme.textLight};
`;

export const Label = ({
    children,
    ...props
}) => (
    <StyledLabel {...props}>
        {children}
    </StyledLabel>
);
