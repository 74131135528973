import React from 'react';
import styled from 'styled-components';

import { useKeyboardEvent } from '../utils';

const Wrapper = styled.div`
    z-index: 20;
    pointer-events: none;
    outline: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    background-color: transparent;
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const Overlay = ({
    onBlur = () => {},
    dismissOnEscape = false,
    children,
}) => {
    const escapeHandler = dismissOnEscape ? onBlur : () => {};
    useKeyboardEvent('Escape', escapeHandler);

    return (
        <Wrapper>
            <Background onClick={onBlur} />
            {children}
        </Wrapper>
    );
};
