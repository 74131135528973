import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';

import './app.css';

import { ThemeProvider } from '~/ui';
import { SurveyProvider } from './contexts';
import { Router } from './Router';

const App = () => (
    <BrowserRouter>
        <ThemeProvider>
            <SurveyProvider>
                <DndProvider options={HTML5toTouch}>
                    <Router />
                </DndProvider>
            </SurveyProvider>
        </ThemeProvider>
    </BrowserRouter>
);

export default App;
