import React from 'react';

import { getCurrentRoute } from '~/core';
import { ROUTES, SURVEY_STEPS } from '~/constants';

const SurveyContext = React.createContext({});

const getNextStep = currentStepIndex => {
    if (currentStepIndex < 0) {
        return SURVEY_STEPS[0];
    }
    if (currentStepIndex === SURVEY_STEPS.length - 1) {
        return ROUTES.THANK_YOU;
    }
    return SURVEY_STEPS[currentStepIndex + 1];
};

const getPrevStep = currentStepIndex => {
    if (currentStepIndex < 0) {
        return null;
    }
    if (currentStepIndex === 0) {
        return ROUTES.BIODATA;
    }
    return SURVEY_STEPS[currentStepIndex - 1];
};

function surveyReducer(state, action) {
    const { payload, type } = action;

    if (type === 'nextStep') {
        return {
            ...state,
            ...payload,
        };
    }

    if (type === 'historyEvent') {
        const { previousRoute, currentRoute } = payload;
        const prevStepIndex = SURVEY_STEPS.indexOf(previousRoute);
        const currentStepIndex = SURVEY_STEPS.indexOf(currentRoute);

        // Reset current step state when navigating to an earlier step
        if (
            currentStepIndex < prevStepIndex &&
            previousRoute !== ROUTES.STANDARD_DESCRIPTORS
        ) {
            return {
                ...state,
                ...initialStateByRoute[previousRoute],
            };
        } else {
            return state;
        }
    }

    return { ...state, ...payload };
}

const initialStateByRoute = {
    [ROUTES.BIODATA]: {
        biodata: {},
    },
    [ROUTES.PERSONAL_DESCRIPTORS]: {
        personalDescriptors: [{ value: '' }],
    },
    [ROUTES.STANDARD_DESCRIPTORS]: {
        standardDescriptors: null,
    },
    [ROUTES.REFINE_DESCRIPTORS]: {
        refinedDescriptors: null,
    },
    [ROUTES.RANK_DESCRIPTORS]: {
        rankedDescriptors: null,
    },
    [ROUTES.DESCRIPTOR_EXAMPLES]: {
        descriptorExamples: null,
        descriptorExamplesParsed: null,
    },
    [ROUTES.DESCRIPTOR_SENTIMENT]: {
        descriptorSentiments: null,
    },
};

const initialState = {
    ...Object.assign(...Object.values(initialStateByRoute)),
};

export const SurveyProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(surveyReducer, initialState);

    const route = getCurrentRoute();
    const currentStepIndex = SURVEY_STEPS.indexOf(route);

    return (
        <SurveyContext.Provider
            value={{
                ...state,
                dispatch,
                currentStepIndex,
                nextStepRoute: getNextStep(currentStepIndex),
                prevStepRoute: getPrevStep(currentStepIndex),
            }}
        >
            {children}
        </SurveyContext.Provider>
    );
};

export const useSurvey = () => React.useContext(SurveyContext);
