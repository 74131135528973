import React from 'react';
import styled from 'styled-components';

import { Button, Icon, Link } from '~/ui';
import { SURVEY_STEPS } from '~/constants';

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 38px;
    padding: 16px 16px 0px 16px;
`;

const Steps = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
`;

const PrevButton = styled(Button)`
    flex: none;
`;

const Title = styled.h3`
    flex: 1;
    margin-right: 40px;
    text-align: center;
`;

const ProgressBar = styled.div`
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background-color: ${p => p.theme.periwinkle};
`;

const ProgressBarFill = styled.div`
    display: block;
    height: 5px;
    border-radius: 5px;
    transition: width 500ms ease-in-out;
    width: ${p => p.percentageComplete}%;
    background-color: ${p => p.theme.primary};
`;

export const Header = ({ currentStep, prevStepRoute }) => {
    const showHeader = currentStep > 0 && currentStep !== SURVEY_STEPS.length + 1;

    if (!showHeader) return null;

    return (
        <HeaderContainer>
            <Steps>
                <Link unstyled to={prevStepRoute}>
                    <PrevButton icon>
                        <Icon type="chevronLeft" />
                    </PrevButton>
                </Link>
                <Title>{`${currentStep} of ${SURVEY_STEPS.length}`}</Title>
            </Steps>
            <ProgressBar>
                <ProgressBarFill
                    percentageComplete={
                        (currentStep / SURVEY_STEPS.length) * 100
                    }
                />
            </ProgressBar>
        </HeaderContainer>
    );
};
