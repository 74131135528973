// https://www.color-blindness.com/color-name-hue/
const colors = {
    white: '#ffffff',
    aliceBlue: '#f1f7ff',
    aliceBlueDark: '#ecf4ff',
    pattensBlue: '#d2deef',
    periwinkle: '#cdd7e5',
    spindle: '#c2cede',
    heather: '#acb5c3',
    baliHai: '#8e9bae',
    fiord: '#56606f',
    tuna: '#484e58',
    ebonyClay: '#32363d',
    black: '#000000',
    amaranth: '#e61a4b',
    frenchRose: '#f2547a',
    emerald: '#41d76d',
    gorse: '#ffdd28'

};

const swatches = {
    primary: colors.amaranth,
    primaryLight: colors.frenchRose,
    positive: colors.emerald,
    neutral: colors.gorse,
    negative: colors.amaranth,
    background: colors.aliceBlue,
    border: colors.spindle,
    active: colors.pattensBlue,
    heading: colors.ebonyClay,
    text: colors.fiord,
    textLight: colors.baliHai,
};

const typography = {
    fontFamily: "'Rubik', sans-serif",
    fontWeight: {
        regular: 400,
        medium: 500,
        bold: 600,
    },
    fontSize: {
        xsmall: '12px',
        small: '14px',
        medium: '16px',
        large: '18px',
        xlarge: '24px',
        xxlarge: '48px',
    },
};

export default {
    ...colors,
    ...swatches,
    ...typography,
};
