import { useRouteMatch } from 'react-router-dom';

import { ROUTES } from '~/constants';

export function getCurrentRoute() {
    const routeMatches = Object.values(ROUTES).filter(
        route => {
            const match = useRouteMatch(route);
            return match && match.isExact;
        },
    );

    if (routeMatches.length > 1) {
        throw new Error('Multiple route matches detected.');
    }

    return routeMatches[0];
}