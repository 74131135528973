import React from 'react';
import styled, { css } from 'styled-components';

import icons from './svg';

const Wrapper = styled.div`
    height: ${p => p.size};
    width: ${p => p.size};
    ${p => p.fillColor && css`
        & > svg path,
        & > svg rect,
        & > svg circle,
        & > svg ellipse,
        & > svg polygon {
            fill: ${p.fillColor};
        }
    `}
`;

export const Icon = ({
    type,
    color,
    size = '32px',
    ...props
}) => {
    if (!icons.has(type)) {
        return null;
    }

    const IconSvg = icons.get(type);

    return (
        <Wrapper
            fillColor={color}
            size={size}
            {...props}
        >
            <IconSvg />
        </Wrapper>
    );
};
