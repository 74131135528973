import React from 'react';
import styled from 'styled-components';

const StyledHeading = styled.h1`
    margin-bottom: 8px;
    color: ${p => p.theme.heading};
`;

export const Heading = ({
    size = 1,
    children,
    ...props
}) => (
    <StyledHeading
        as={`h${size}`}
        {...props}
    >
        {children}
    </StyledHeading>
);
