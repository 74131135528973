import styled from 'styled-components';

export const Form = styled.form.attrs({
    role: 'form',
    autoComplete: 'off',
})`
    flex: 1;
    margin-bottom: 16px;
    > * {
        margin-bottom: 16px;
    }
    > label {
        margin-bottom: 8px;
    }
`;
