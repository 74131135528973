import React from 'react';
import {
    ThemeContext as StyledThemeContext,
    ThemeProvider as StyledThemeProvider,
} from 'styled-components';

import variables from './variables';

export const ThemeProvider = ({ children }) => (
    <StyledThemeProvider theme={variables}>
        {children}
    </StyledThemeProvider>
);

export const useTheme = () => React.useContext(StyledThemeContext);
