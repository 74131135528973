import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    display: grid;
    place-items: center;
    ${p => p.fillContainer && css`
        width: 100%;
        height: 100%;
    `}
`;

const Spinner = styled.svg`
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;

    & .path {
        stroke: ${p => p.theme.primary};
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;

const SpinnerComponent = () => (
    <Spinner viewBox="0 0 50 50">
        <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
        />
    </Spinner>
)

export const Loading = ({
    fill = true,
    ...props
}) => (
    <Wrapper
        fillContainer={fill}
        {...props}
    >
        <SpinnerComponent />
    </Wrapper>
);
