import React from 'react';
import styled from 'styled-components';

import { Heading } from '~/ui';

const Subtitle = styled.div`
    font-style: italic;
    margin-bottom: 24px;
    p:not(:last-child) {
        margin-bottom: 8px;
    }
`;

export const StepDescription = ({ title, subtitle }) => (
    <>
        <Heading>{title}</Heading>
        <Subtitle>{subtitle}</Subtitle>
    </>
);
