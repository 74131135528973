import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useSurvey } from '~/contexts';
import { usePrevious } from '~/core';
import { Header } from './Header';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    max-width: 767px;
    color: ${p => p.theme.text};
    font-family: ${p => p.theme.fontFamily};
    font-weight: ${p => p.theme.fontWeight.regular};
`;

const Main = styled.main`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;
    overflow-y: auto;
`;

export const Layout = ({ children }) => {
    const mainRef = useRef(null);
    const { dispatch, currentStepIndex, prevStepRoute } = useSurvey();

    const history = useHistory();
    const currentRoute = history.location.pathname;
    const previousRoute = usePrevious(history.location.pathname);

    useEffect(() => {
        dispatch({
            type: 'historyEvent',
            payload: {
                previousRoute,
                currentRoute,
            },
        });

        mainRef.current?.scrollTo({ top: 0 });
    }, [currentRoute]);

    return (
        <Wrapper>
            <Header
                prevStepRoute={prevStepRoute}
                currentStep={currentStepIndex + 1}
            />
            <Main ref={mainRef}>{children}</Main>
        </Wrapper>
    );
};
